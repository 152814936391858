import React, { Component } from 'react'
import './Second.css'
import Grid from '@material-ui/core/Grid'
import DemoImage from './DemoImage'
import { normalize } from '../../utils/normalize'

class Second extends Component {
  render () {
    return (
        <Grid container item xs={12} sm={11} justify="center" spacing={1} className="content-card second">
          <Grid item xs={11}>
            <h2 className="dark-blue">
              { normalize('Étapes 2, 3 et 4') }
            </h2>
            <h3>
              { normalize("Chaque joueur reçoit un mot rentré à l'étape précédente par un autre joueur en haut de son écran.") }
              <br /><br />
              { normalize('Le joueur doit remplir le formulaire au centre de son écran en mettant un mot qui lui' +
                ' fait penser à ce mot-ci.') }
              <br />
              { normalize('Les règles concernant le mot à fournir sont les mêmes que précedemment, à la différence près' +
                ' que la contrainte peut être modifiée ou absente selon les paramètres de la partie.') }
            </h3>
          </Grid>
          <DemoImage src='/static/media/images/howto/step2.jpg' />
        </Grid>
    )
  }
}

export default Second
