import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import './image.css'
import PropTypes from 'prop-types'

class PrettyBottomRow extends Component {
  static get propTypes () {
    return {
      column: PropTypes.bool,
      style: PropTypes.any,
      children: PropTypes.any
    }
  }

  render () {
    const cornerDown = {
      backgroundImage: 'url(./static/media/images/corner-down.png)',
      backgroundPositionY: 'bottom'
    }

    if (this.props.column) {
      return (
          <Grid container item justify="center" className="image-row" style={{ padding: 0 }}>
            <Grid item xs={2} style={cornerDown} className="image"/>
            <Grid item xs={8} style={this.props.style}>
              { this.props.children }
            </Grid>
            <Grid item xs={2} style={cornerDown} className="image revert"/>
          </Grid>
      )
    }

    return (
        <Grid container item xs={12} justify="center" className="image-row" style={{ padding: 0 }}>
          <Grid item xs={2} style={cornerDown} className="image"/>
          <Grid item xs={8} style={this.props.style}>
            {this.props.children}
          </Grid>
          <Grid item xs={2} style={cornerDown} className="image revert"/>
        </Grid>
    )
  }
}

export default PrettyBottomRow
