import React, { Component } from 'react'
import './Guesses.css'
import Grid from '@material-ui/core/Grid'
import DemoImage from './DemoImage'
import { normalize } from '../../utils/normalize'

class Guesses extends Component {
  render () {
    return (
        <Grid container item xs={12} sm={11} justify="center" spacing={1} className="content-card guesses">
          <Grid item xs={11}>
            <h2 className="dark-blue">
              { normalize('Étapes de divination') }
            </h2>
            <h3>
              { normalize("Chaque mot envoyé lors de l'étape 4 est affiché au centre de l'écran.") }
              <br />
              { normalize("En bas de l'écran figurent 8 personnages, incluant les personnages initiaux de chaque joueur," +
                " ainsi qu'un rappel des différentes contraintes appliquées lors de la partie.") }
              <br /><br />
              { normalize('Lors de cette phase, les joueurs doivent essayer de deviner à quel personnage correspond chaque' +
                " mot envoyé à l'étape 4.") }
              <br />
              { normalize("Pour effectuer une supposition, il suffit de cliquer sur une carte contenant un mot de l'étape 4" +
                ' et de sélectionner un personnage dans le menu qui apparait.') }
              <br />
              { normalize('Une fois toutes les suppositions faites, le joueur peut les valider en cliquant sur le bouton' +
                " en haut de l'écran (non affiché sur la capture d'écran ci-dessous).") }
              <br /><br />
              { normalize('Note : La difficulté de cette étape varie grandement selon les joueurs, les personnages, et' +
                ' les contraintes de la partie. Si vous trouvez que votre partie était trop facile, essayez de rajouter plus de' +
                ' contraintes pour la prochaine !') }
            </h3>
          </Grid>
          <DemoImage src='/static/media/images/howto/step3.jpg' />
        </Grid>
    )
  }
}

export default Guesses
