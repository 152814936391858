import React, { Component } from 'react'
import './CreateGameForm.css'
import { Input, Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import PrettyTopRow from '../common/PrettyTopRow'
import PrettyTitle from '../common/PrettyTitle'
import PrettyBottomRow from '../common/PrettyBottomRow'
import ReactRouterPropTypes from 'react-router-prop-types'

class CreateGameForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      invalid: false,
      creating: false,
      created: false,
      existed: false,
      id: '',
      private: true
    }

    this.createGame = this.createGame.bind(this)
    this.created = this.created.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  static get propTypes () {
    return {
      history: ReactRouterPropTypes.history.isRequired
    }
  }

  checkName (name) {
    return name.length > 0 && name.length <= 32 && name.match(/^[a-zA-Z0-9]+$/)
  }

  createGame (id) {
    this.setState({ invalid: false })
    if (!this.checkName(id)) {
      this.setState({ invalid: true, creating: false })
      return
    }

    fetch('api/createGame?', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        game_id: id,
        private: this.state.private
      })
    }).then(response => {
      if (response.status >= 400) {
        this.setState({ // Invalid name
          invalid: true,
          creating: false,
          created: false,
          id: ''
        })
      } else {
        this.setState({
          existed: response.status === 200,
          creating: false,
          created: true,
          id: id
        })
      }
    })
  }

  created () {
    return this.state.created && !this.state.creating && this.state.id !== ''
  }

  submitForm (event) {
    this.setState({ creating: true })
    this.createGame(this.state.id)

    // Prevent form redirection
    event.preventDefault()
  }

  render () {
    const mid = {
      backgroundImage: 'url(./static/media/images/mid-side.png)',
      backgroundPosition: 'left',
      height: '60px'
    }

    return (
        <Grid container item xs={12} sm={11} justify="center" spacing={1} className="content-card create-game-form">
          <form onSubmit={this.submitForm}>
            <PrettyTopRow/>
            <PrettyTitle/>
            <Grid item xs={12}>
              {
                !this.created() &&
                <h2 className="dark-blue">
                  Entrez un nom pour votre partie :
                </h2>
              }
            </Grid>
            <Grid container item xs={12} justify="center">
              <Grid item xs={false} sm={1} md={2} lg={3} style={mid} className="image"/>
              <Grid item xs={11} sm={10} md={8} lg={6}>
                {
                  this.state.creating &&
                  <h2 className="dark-blue">
                    Creation...
                  </h2>
                }
                {
                  this.created() &&
                  !this.state.existed &&
                  <h2 className="dark-blue">
                    La partie <span className="handwriting game-name">{this.state.id}</span> a ete creee !
                  </h2>
                }
                {
                  this.created() &&
                  this.state.existed &&
                  <h2 className="dark-blue">
                    La partie <span className="handwriting game-name">{this.state.id}</span> existe deja !
                  </h2>
                }
                {
                  !this.created() &&
                  !this.state.creating &&
                  <Input fullWidth
                         onChange={(event) => this.setState({ id: event.target.value })}
                         color="secondary" />
                }
              </Grid>
              <Grid item xs={false} sm={1} md={2} lg={3} style={mid} className="image revert"/>
            </Grid>
            {
              !this.created() &&
              !this.state.creating &&
              <Grid item xs={12} style={{ marginBottom: '15px' }}>
                <FormControlLabel control={
                  <Checkbox onChange={(event) => {
                    this.setState({ private: event.target.checked })
                  }}
                            name="private"
                            color="secondary"
                            checked={this.state.private} />
                } label="Partie privee"
                                  classes={{
                                    root: 'private-body',
                                    label: 'private-label'
                                  }}/>
              </Grid>
            }
            <Grid item xs={12}>
              {
                !this.created() &&
                !this.state.creating &&
                <Button type="submit"
                        variant="contained"
                        color="primary">
                  Creer une partie
                </Button>
              }
              {
                this.created() &&
                <Button onClick={() => this.props.history.push('/game?game_id=' + this.state.id)}
                        variant="contained"
                        color="primary">
                  Rejoindre la partie
                </Button>
              }
            </Grid>
            <Grid item xs={11}>
              {
                this.state.invalid &&
                <span className="orange" style={{ fontSize: '1.5rem' }}>
                  Erreur : le nom de la partie doit etre un seul mot avec uniquement des chiffres et des lettres
                </span>
              }
            </Grid>
            <PrettyBottomRow/>
          </form>
        </Grid>
    )
  }
}

export default CreateGameForm
