import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import PrettyTopRow from '../../common/PrettyTopRow'
import PrettyBottomRow from '../../common/PrettyBottomRow'
import './GlobalResults.css'

class GlobalResults extends Component {
  constructor (props) {
    super(props)

    this.state = {
      playersAmount: this.props.results.filter(res => res.player_guesses.length > 0).length
    }

    this.playerPoints = this.playerPoints.bind(this)
    this.totalAssociations = this.totalAssociations.bind(this)
    this.gamePoints = this.gamePoints.bind(this)
  }

  static get propTypes () {
    return {
      results: PropTypes.array,
      players: PropTypes.array,
      playerIndex: PropTypes.number
    }
  }

  playerPoints () {
    let score = 0
    for (const result of this.props.results) {
      const playerGuess = result.player_guesses.find(g => g.player_index === this.props.playerIndex)
      if (playerGuess) {
        score += playerGuess.skull_index === playerGuess.character_index ? 1 : 0
      }
    }
    return score
  }

  totalAssociations () {
    let score = 0
    for (const result of this.props.results) {
      for (const guess of result.player_guesses) {
        if (guess.skull_index === guess.character_index) {
          score++
        }
      }
    }
    return score
  }

  gamePoints () {
    const found = [...Array(this.state.playersAmount).keys()].map(_ => this.state.playersAmount)

    for (const result of this.props.results) {
      for (const guess of result.player_guesses) {
        if (guess.skull_index === guess.character_index) {
          found[guess.skull_index]--
        }
      }
    }

    let bonus = this.state.playersAmount === 8 ? 4 : this.state.playersAmount % 4
    bonus += found.filter(v => v === 0).length

    const bonused = found.filter(v => v > 1).map(v => v - 1).sort().map(v => {
      if (bonus < v) { return v }
      bonus -= v
      return 0
    })

    return found.filter(v => v <= 1).length + bonused.filter(v => v === 0).length
  }

  render () {
    return (
        <Grid container item justify="center" xs={12} sm={11} className="content-card global-results">
          <PrettyTopRow />
          <Grid item xs={12} sm={11}>
            <h2 className="dark-blue">
              Resultats :
            </h2>
          </Grid>
          <Grid item xs={12} sm={11}>
            <h3>
              Tu as trouve { this.playerPoints() } associations sur { this.state.playersAmount }
            </h3>
          </Grid>
          <Grid item xs={12} sm={11}>
            <h3>
              Au total, { this.totalAssociations() } associations ont ete trouvees sur { Math.pow(this.state.playersAmount, 2) }
            </h3>
          </Grid>
          <Grid item xs={12} sm={11}>
            <h3>
              Vous avez un total de { this.gamePoints() } points sur { this.state.playersAmount }
            </h3>
          </Grid>
          <PrettyBottomRow />
        </Grid>
    )
  }
}

export default GlobalResults
