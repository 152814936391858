import React, { Component } from 'react'
import './GamePresentation.css'
import Grid from '@material-ui/core/Grid'
import PrettyTopRow from '../common/PrettyTopRow'
import PrettyTitle from '../common/PrettyTitle'
import PrettyBottomRow from '../common/PrettyBottomRow'
import { normalize } from '../../utils/normalize'

class GamePresentation extends Component {
  render () {
    return (
        <Grid container item xs={12} sm={11} justify="center" spacing={1} className="content-card game-presentation">
          <PrettyTopRow/>
          <PrettyTitle/>
          <Grid item xs={11}>
            <h2 className="dark-blue">
              { normalize('Présentation globale') }
            </h2>
            <h3>
              { normalize("Fiesta De Los Muertos est un jeu d'ambiance coopératif pour entre 4 et 8 joueurs.") }
              <br /><br />
              { normalize('En début de partie, chaque joueur recevra un nom de personne ou personnage décédé, et devra écrire' +
              ' un mot qui lui fait penser à ce personnage.') }
              <br />
              { normalize('Lors des phases suivantes, ces mots passeront de joueur en joueur, et chacun devra écrire un autre mot' +
              ' qui lui y fait penser, sans connaitre le nom du personnage original.') }
              <br />
              { normalize("L'objectif final sera que le plus de joueurs possible retrouve à quel personnage est associé chaque mot.") }
              <br /><br />
              { normalize("Cela vous parait difficile ? Il n'en est rien, du moins en ce qui concerne le fonctionnement du jeu.") }
              <br />
              { normalize("Cette page présente chaque étape d'une partie avec une explication des règles, et des captures d'écran" +
                " de l'interface ordinateur (l'interface mobile est très similaire).") }
            </h3>
            <h2 className="dark-blue">
              { normalize('Notes importantes') }
            </h2>
            <h3>
              { normalize("Ce jeu se joue en plusieurs étapes. Pour chaque étape il est nécessaire que l'intégralité des" +
                ' joueurs ait terminé afin de passer à la suivante. Cette plateforme vous informera en permanence pendant les' +
                " parties de l'état de chaque joueur pour l'étape courante en bas de page : étape en cours, terminée ou joueur" +
                ' déconnecté.') }
              <br />
              { normalize('Cette plateforme propose un système de reconnexion testé comme suffisamment fiable mais pas infaillible.' +
                ' Si vous faites face à un problème avec la reconnexion, il est toujours possible de recommencer une partie à partir de' +
                ' zéro avec un bouton en toute fin de chaque page du jeu.') }
            </h3>
            <h3>
              { normalize("Bien qu'il soit possible de jouer en étant présent uniquement sur cette plateforme, il est recommandé pour une" +
              ' meilleure expérience que les joueurs puissent également communiquer de manière vocale, en étant dans la même' +
              ' pièce ou bien en utilisant une plateforme de chat telle que Discord, Zoom, WhatsApp, etc...') }
            </h3>
          </Grid>
          <PrettyBottomRow/>
        </Grid>
    )
  }
}

export default GamePresentation
