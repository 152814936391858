import React, { Component } from 'react'
import './Results.css'
import Grid from '@material-ui/core/Grid'
import { normalize } from '../../utils/normalize'

class Guesses extends Component {
  render () {
    return (
        <Grid container item xs={12} sm={11} justify="center" spacing={1} className="content-card results">
          <Grid item xs={11}>
            <h2 className="dark-blue">
              { normalize('Résultats') }
            </h2>
            <h3>
              { normalize('La partie est terminée !') }
              <br /><br />
              { normalize('Le site affiche en haut les résultats globaux de la partie, avec votre score personnel' +
                ' ainsi que le score de la partie en comptabilisant les suppositions de tous les joueurs.') }
              <br />
              { normalize('Le score de la partie est calculé selon le nombre de joueurs qui ont deviné correctement chaque' +
                ' association, en ajoutant des éventuels bonus selon le nombre de joueurs.') }
              <br /><br />
              { normalize('Au centre de la page, vous pouvez observer toutes les informations sur le contenu de la partie :') }
              <ul>
                <li>{ normalize('Quels mots ont été écrits et par quel joueur') }</li>
                <li>{ normalize('À quel personnage correspond chaque suite de mots') }</li>
                <li>{ normalize('Quelles ont été les suppositions des joueurs') }</li>
              </ul>
              { normalize("(un bouton \"Suite\" est présent pour passer d'un affichage à l'autre sous chaque mot)") }
              <br /><br />
              { normalize('Maintenant que vous avez votre score, il est temps de recommencer une partie en cliquant sur le bouton' +
                ' en bas de page pour essayer de faire mieux, ou réessayer en modifiant le nombre de contraintes !') }
            </h3>
          </Grid>
        </Grid>
    )
  }
}

export default Guesses
