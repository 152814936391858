import React, { Component } from 'react'
import './First.css'
import Grid from '@material-ui/core/Grid'
import DemoImage from './DemoImage'
import { normalize } from '../../utils/normalize'

class First extends Component {
  render () {
    return (
        <Grid container item xs={12} sm={11} justify="center" spacing={1} className="content-card first">
          <Grid item xs={11}>
            <h2 className="dark-blue">
              { normalize('Première étape') }
            </h2>
            <h3>
              { normalize('Chaque joueur a un personnage différent affiché en haut de son écran.') }
              <br />
              { normalize('Si un joueur ne connait pas le personnage qui lui est attribué, il peut cliquer sur le bouton' +
              ' "Demander à changer de personnage" pour en recevoir un nouveau.') }
              <br /><br />
              { normalize('Le joueur doit remplir le formulaire au centre de son écran en mettant un mot qui lui' +
                ' fait penser à son personnage.') }
              <br />
              { normalize('Les règles concernant le mot à fournir sont les suivantes :') }
              <ul>
                <li>{ normalize("Un seul mot (mots composés autorisés à condition qu'il y ait des tirets)") }</li>
                <li>{ normalize("Pas de nom de personne / personnage (qu'iel soit vivant ou décédé)") }</li>
                <li>{ normalize('Pas de mot de la même famille que le mot précédent (voir étapes suivantes)') }</li>
              </ul>
              { normalize('Selon les paramètres définis avant de commencer la partie, une contrainte peut être donnée' +
                ' en bas de page. Cette contrainte se rajoute aux règles ci-dessus pour ce tour-ci uniquement.') }
              <br /><br />
              { normalize('ATTENTION : Une fois un mot envoyé, il est impossible de le modifier !') }
            </h3>
          </Grid>
          <DemoImage src='/static/media/images/howto/step1.jpg' />
        </Grid>
    )
  }
}

export default First
