import React, { Component } from 'react'
import './Card.css'
import { CardTypeEnum } from '../../../utils/enums'
import PropTypes from 'prop-types'

class Card extends Component {
  static get propTypes () {
    return {
      type: PropTypes.number,
      children: PropTypes.any,
      maxHeight: PropTypes.string
    }
  }

  render () {
    let backgroundImage = ''
    let gameCard = false
    switch (this.props.type) {
      case CardTypeEnum.CONSTRAINT:
        backgroundImage = 'constraint'
        gameCard = true
        break
      case CardTypeEnum.CHARACTER:
        backgroundImage = 'card'
        gameCard = true
        break
      case CardTypeEnum.THEME:
        backgroundImage = 'theme_card'
        gameCard = true
        break
      case CardTypeEnum.FST_WORD:
        backgroundImage = 'turns/1'
        break
      case CardTypeEnum.SEC_WORD:
        backgroundImage = 'turns/2'
        break
      case CardTypeEnum.TRD_WORD:
        backgroundImage = 'turns/3'
        break
      case CardTypeEnum.FTH_WORD:
        backgroundImage = 'turns/4'
        break
      case CardTypeEnum.RESULT_1:
        backgroundImage = 'results/1'
        break
      case CardTypeEnum.RESULT_2:
        backgroundImage = 'results/2'
        break
      case CardTypeEnum.RESULT_3:
        backgroundImage = 'results/3'
        break
      case CardTypeEnum.RESULT_4:
        backgroundImage = 'results/4'
        break
      default:
        break
    }

    const style = this.props.maxHeight ? { maxHeight: this.props.maxHeight } : {}

    return (
      <div className="card">
        <img src={'./static/media/images/' + backgroundImage + '.png'} alt="card"/>
        <div className={'card-foreground' + (gameCard ? ' game-card' : '')} style={style}>
          { this.props.children }
        </div>
      </div>
    )
  }
}

export default Card
