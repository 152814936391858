import React from 'react'
import './InputSkull.css'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import PrettyTopRow from '../../common/PrettyTopRow'
import PrettyBottomRow from '../../common/PrettyBottomRow'
import PropTypes from 'prop-types'

class InputSkull extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      sendWord: props.sendWord,
      error: false,
      word: ''
    }

    this.sendWord = this.sendWord.bind(this)
    this.validWord = this.validWord.bind(this)
  }

  static get propTypes () {
    return {
      sendWord: PropTypes.func,
      turn: PropTypes.number,
      ack: PropTypes.bool
    }
  }

  componentDidUpdate (prevProps) {
    if (!this.props.ack && prevProps.ack && this.state.word !== '') {
      this.setState({ word: '' })
    }
  }

  sendWord (event) {
    this.setState({ error: false })
    if (this.validWord(this.state.word)) {
      this.state.sendWord(this.state.word)
    } else {
      this.setState({ error: true })
    }

    // Prevent form redirection
    event.preventDefault()
  }

  validWord (word) {
    return word.length > 0 && word.length <= 32 && word.match(/^[^ \t\n\r\f\v_.]+$/)
  }

  render () {
    return (
        <Grid container item xs={12} sm={11} justify="center" className="content-card input-skull">
          <form onSubmit={this.sendWord}>
            <PrettyTopRow/>
            <Grid item xs={12}>
              <h2 className="dark-blue">
                Ecrivez un mot associe a ce {this.props.turn === 1 ? 'personnage' : 'mot'} en respectant la contrainte
              </h2>
            </Grid>
            <Grid container item justify="center" xs={12}>
              <Grid item xs={11} sm={10} md={8} lg={6} className="handwriting">
                <TextField label={'Mot associe'}
                           value={this.state.word}
                           fullWidth
                           color="secondary"
                           onChange={(event) => this.setState({ word: event.target.value })}
                           disabled={this.props.ack}/>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              {
                !this.props.ack &&
                <Button type="submit" variant="contained" color="primary" disabled={this.props.ack}>
                  Envoyer le mot
                </Button>
              }
              {
                this.props.ack &&
                <h2>
                  Mot envoye !<br/>
                  <span>
                    Attente des autres joueurs
                  </span>
                </h2>
              }
            </Grid>
            {
              this.state.error &&
              <Grid item xs={11}>
                          <span className="orange" style={{ fontSize: '1.5rem' }}>
                            { "Votre mot est invalide. Il doit avoir entre 1 et 32 caracteres et ne faire qu'un seul mot ('-' acceptes pour les mots composes)." }
                          </span>
              </Grid>
            }
            <PrettyBottomRow>
                      <span style={{ fontSize: '1.5rem', display: 'block', marginTop: '20px' }}>
                          Tour {this.props.turn}/4
                      </span>
            </PrettyBottomRow>
          </form>
        </Grid>
    )
  }
}

export default InputSkull
