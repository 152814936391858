import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import './title.css'

class PrettyTitle extends Component {
  render () {
    return (
        <Grid item xs={12}>
          <h1 className="title main-title">
            <span className={'light-blue'}>F</span>
            <span className={'orange'}>i</span>
            <span className={'yellow'}>e</span>
            <span className={'light-blue'}>s</span>
            <span className={'orange'}>t</span>
            <span className={'yellow'}>a</span>
            <span> </span>
            <span className={'light-blue'}>d</span>
            <span className={'orange'}>e</span>
            <span> </span>
            <span className={'yellow'}>l</span>
            <span className={'light-blue'}>o</span>
            <span className={'orange'}>s</span>
            <span> </span>
            <span className={'yellow'}>m</span>
            <span className={'light-blue'}>u</span>
            <span className={'orange'}>e</span>
            <span className={'yellow'}>r</span>
            <span className={'light-blue'}>t</span>
            <span className={'orange'}>o</span>
            <span className={'yellow'}>s</span>
          </h1>
          <h4 className={'subtitle'}>
            (aka Le Jeu De Ses Morts)
          </h4>
        </Grid>
    )
  }
}

export default PrettyTitle
