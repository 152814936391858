import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

class DemoImage extends Component {
  static get propTypes () {
    return {
      src: PropTypes.string
    }
  }

  render () {
    const style = {
      backgroundImage: 'url(' + this.props.src + ')',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      height: '25rem',
      marginBottom: '10px',
      cursor: 'pointer'
    }

    return (
        <Grid item xs={11} sm={10} md={8} style={style} onClick={() => window.open(this.props.src, '_blank')} />
    )
  }
}

export default DemoImage
