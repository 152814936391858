import React from 'react'
import './Howto.css'
import Grid from '@material-ui/core/Grid'
import GamePresentation from './GamePresentation'
import First from './First'
import Second from './Second'
import Guesses from './Guesses'
import Results from './Results'
import Button from '@material-ui/core/Button'
import { normalize } from '../../utils/normalize'
import { useHistory } from 'react-router'

function Howto () {
  const history = useHistory()
  return (
      <Grid container item xs={12} justify="center" className="howto">
        <GamePresentation />
        <First />
        <Second />
        <Guesses />
        <Results />
        <Grid item xs={11} style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button variant="contained" color="secondary" onClick={() => history.push('/')}>{ normalize("Retour à l'accueil") }</Button>
        </Grid>
      </Grid>
  )
}

export default Howto
