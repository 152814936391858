import React, { Component } from 'react'
import './GameInfoRow.css'
import { normalize } from '../../../utils/normalize'
import { CardTypeEnum } from '../../../utils/enums'
import Grid from '@material-ui/core/Grid'
import Card from '../common/Card'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

class GameInfoRow extends Component {
  static get propTypes () {
    return {
      askNewCharacter: PropTypes.func,
      type: PropTypes.number,
      prevWord: PropTypes.string,
      constraint: PropTypes.string,
      theme1: PropTypes.string,
      theme2: PropTypes.string
    }
  }

  themeName (theme) {
    if (theme === 'custom') {
      return 'Personnalisé'
    } else if (theme === 'default') {
      return 'Par défaut'
    } else {
      return theme
    }
  }

  render () {
    if (this.props.type === CardTypeEnum.THEME) {
      return (
        <Grid container item justify="center" alignItems="stretch" spacing={5} xs={12} className="game-info-row">
          <Grid item xs={11} style={{ display: 'flex' }}>
            <h3 className="center-text">
              { normalize('Thèmes de la partie :') }
            </h3>
          </Grid>
          <Grid container item justify="center" xs={11} sm={6} md={4} lg={3}>
            <Card type={CardTypeEnum.THEME}>
              <h3 className="orange">
                { normalize(this.themeName(this.props.theme1)) }
              </h3>
            </Card>
          </Grid>
          {
            this.props.theme2.length > 0 &&
            <Grid container item justify="center" xs={11} sm={6} md={4} lg={3}>
              <Card type={CardTypeEnum.THEME}>
                <h3 className="orange">
                  { normalize(this.themeName(this.props.theme2)) }
                </h3>
              </Card>
            </Grid>
          }
        </Grid>
      )
    }

    if (this.props.type === CardTypeEnum.CONSTRAINT && !this.props.constraint) {
      return (
          <Grid item xs={11} className="game-info-row">
            <h3 style={{ marginTop: '50px', marginBottom: '50px', textAlign: 'center' }}>
              { normalize('Pas de contrainte pour ce tour') }
            </h3>
          </Grid>
      )
    }

    const contentClass = this.props.type === CardTypeEnum.CONSTRAINT ? '' : this.props.type === CardTypeEnum.CHARACTER ? 'dark-blue' : 'handwriting'
    const contentStyle = this.props.type === CardTypeEnum.CONSTRAINT ? { color: '#ffffff' } : {}

    return (
        <Grid container item justify="center" alignItems="stretch" spacing={5} xs={12} className="game-info-row">
          <Grid item xs={11} sm={11} md={4} style={{ display: 'flex' }}>
            <h3 className="center-text">
              {
                this.props.type === CardTypeEnum.CONSTRAINT
                  ? 'Contrainte :'
                  : this.props.type === CardTypeEnum.CHARACTER
                    ? 'Votre personnage :'
                    : 'Mot recu :'
              }
            </h3>
          </Grid>
          <Grid container item justify="center" xs={11} sm={6} md={4} lg={3}>
            <Card type={this.props.type}>
              <h3 className={contentClass} style={contentStyle}>
                {
                  this.props.type === CardTypeEnum.CONSTRAINT
                    ? normalize(this.props.constraint)
                    : this.props.type === CardTypeEnum.CHARACTER
                      ? normalize(this.props.prevWord)
                      : normalize(this.props.prevWord)
                }
              </h3>
            </Card>
          </Grid>
          <Grid container item justify="center" xs={11} sm={11} md={4}>
            {
              this.props.type === CardTypeEnum.CHARACTER &&
              <Button variant="contained"
                      color="primary"
                      onClick={this.props.askNewCharacter}
                      style={{ marginTop: 'auto', marginBottom: 'auto' }}
                      disabled={this.props.askNewCharacter === null}>
                { normalize('Demander à changer de personnage') }
              </Button>
            }
          </Grid>
        </Grid>
    )
  }
}

export default GameInfoRow
