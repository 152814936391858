import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import './Board.css'
import { normalize } from '../../../utils/normalize'
import PlayerList from '../common/PlayerList'
import { CardTypeEnum, GameStateEnum } from '../../../utils/enums.js'
import InputSkull from './InputSkull'
import GuessSkull from './GuessSkull'
import Button from '@material-ui/core/Button'
import ResultSkull from './ResultSkull'
import GameInfoRow from './GameInfoRow'
import PropTypes from 'prop-types'
import PrettyBaseCard from '../../common/PrettyBaseCard'
import Card from '../common/Card'
import GlobalResults from './GlobalResults'

class Board extends Component {
  constructor (props) {
    super(props)

    this.state = {
      guesses: {},
      sendGuesses: props.sendGuesses,
      missingGuesses: true,
      askNewCharacter: props.askNewCharacter
    }

    this.turnNumber = this.turnNumber.bind(this)
    this.setGuess = this.setGuess.bind(this)
    this.sendGuesses = this.sendGuesses.bind(this)
    this.drawWordState = this.drawWordState.bind(this)
    this.drawGuessState = this.drawGuessState.bind(this)
    this.drawResultsState = this.drawResultsState.bind(this)
  }

  static get propTypes () {
    return {
      sendGuesses: PropTypes.func,
      askNewCharacter: PropTypes.func,
      sendWord: PropTypes.func,
      game: PropTypes.any,
      guessWords: PropTypes.array,
      constraints: PropTypes.array,
      characters: PropTypes.array,
      players: PropTypes.array,
      results: PropTypes.array,
      prevWord: PropTypes.string,
      playerName: PropTypes.string,
      ack: PropTypes.bool,
      useTheme: PropTypes.bool,
      theme1: PropTypes.string,
      theme2: PropTypes.string
    }
  }

  turnNumber () {
    switch (this.props.game.state) {
      case GameStateEnum.FST_WORD:
        return 1
      case GameStateEnum.SEC_WORD:
        return 2
      case GameStateEnum.TRD_WORD:
        return 3
      case GameStateEnum.FTH_WORD:
        return 4
      default:
        return -1
    }
  }

  setGuess (skullIndex, characterIndex) {
    const guesses = this.state.guesses
    guesses[skullIndex] = characterIndex
    this.setState({
      guesses: guesses,
      missingGuesses: Object.keys(guesses).length < this.props.guessWords.length
    })
  }

  sendGuesses () {
    if (!this.state.missingGuesses) {
      this.state.sendGuesses(this.state.guesses)
    }
  }

  drawWordState () {
    let constraint = null
    if (this.props.constraints && this.props.constraints.length >= this.turnNumber()) {
      constraint = this.props.constraints.find(c => c.index === this.turnNumber() - 1).constraint
    }

    return (
        <Grid container item justify="center" xs={12} className="board">
          <GameInfoRow type={this.turnNumber() - 1} prevWord={this.props.prevWord} askNewCharacter={this.props.ack ? null : this.state.askNewCharacter} />
          <InputSkull sendWord={this.props.sendWord} ack={this.props.ack} turn={this.turnNumber()}/>
          <GameInfoRow type={CardTypeEnum.CONSTRAINT} constraint={constraint} />
          {
            this.props.useTheme &&
            <GameInfoRow type={CardTypeEnum.THEME} theme1={this.props.theme1} theme2={this.props.theme2} />
          }
          <PlayerList players={this.props.players} playerName={this.props.playerName} />
        </Grid>
    )
  }

  drawGuessState () {
    if (this.props.characters.length === 0 || this.props.guessWords.length === 0) {
      return (
          <PrettyBaseCard className="board">
            <h2 className="dark-blue">
              { 'Chargement des donnees...' }
            </h2>
          </PrettyBaseCard>
      )
    }

    return (
        <Grid container item justify="center" xs={12} className="board">
          <PrettyBaseCard large style={{ marginBottom: '15px' }} bottom={
            <Button variant="contained" color="primary" onClick={this.sendGuesses}
                    disabled={this.state.missingGuesses || this.props.ack}>Envoyer les suggestions</Button>
          }>
            <h3 className="dark-blue" style={{ marginTop: '10px', marginBottom: '15px', fontSize: '2.2rem' }}>
              {
                'Associez un mot a un personnage en cliquant sur la carte du mot, ' +
                'et validez vos reponses en cliquant sur le bouton ci dessous.'
              }
              <br />
              { 'Vous pouvez visualiser toutes les cartes personnages en bas de cette page !' }
            </h3>
          </PrettyBaseCard>
          <Grid container item justify="center" xs={12} sm={10} spacing={2}>
            {
              this.props.guessWords.map((guessWord, i) =>
                <GuessSkull key={i}
                            guessWord={guessWord.word}
                            setGuess={(characterIndex) => {
                              this.setGuess(guessWord.index, characterIndex)
                            }}
                            characters={this.props.characters}
                            ack={this.props.ack}/>
              )
            }
          </Grid>
          <Grid container item justify="center" xs={12} sm={10} spacing={2} style={{ marginTop: '10px' }}>
            {
              this.props.characters.map((character, i) =>
                  <Grid key={i} item xs={8} sm={6} md={4} lg={3}>
                    <Card type={CardTypeEnum.CHARACTER}>
                      <h3 className="dark-blue">
                        { normalize(character.name) }
                      </h3>
                    </Card>
                  </Grid>
              )
            }
          </Grid>
          <Grid container item justify="center" xs={12} sm={10} spacing={2} style={{ marginTop: '10px' }}>
            {
              this.props.constraints.map((constraint, i) =>
                  <Grid key={i} item xs={8} sm={6} md={4} lg={3}>
                    <Card type={CardTypeEnum.CONSTRAINT}>
                      <h3 style={{ color: '#ffffff' }}>
                        { normalize(constraint.constraint) }
                      </h3>
                    </Card>
                  </Grid>
              )
            }
          </Grid>
          {
            this.props.useTheme &&
            <GameInfoRow type={CardTypeEnum.THEME} theme1={this.props.theme1} theme2={this.props.theme2} />
          }
          <PlayerList players={this.props.players} playerName={this.props.playerName} style={{ marginTop: '10px' }} />
        </Grid>
    )
  }

  drawResultsState () {
    if (this.props.characters.length === 0 || this.props.results.length === 0) {
      return (
          <PrettyBaseCard className="board">
            <h2 className="dark-blue">
              { 'Chargement des resultats...' }
            </h2>
          </PrettyBaseCard>
      )
    }

    const playerIndex = this.props.players.find(p => p.name === this.props.playerName).index

    return (
        <Grid container item justify="center" xs={12} className="board">
          <GlobalResults results={this.props.results} players={this.props.players} playerIndex={playerIndex} />
          <Grid container item justify="center" xs={12} sm={10} spacing={2} style={{ marginTop: '10px' }}>
            {
              this.props.results.filter(result => result.played).map((result, i) =>
                  <ResultSkull key={i} result={result} characters={this.props.characters} players={this.props.players}/>
              )
            }
          </Grid>
        </Grid>
    )
  }

  render () {
    switch (this.props.game.state) {
      case GameStateEnum.FST_WORD:
        return this.drawWordState()
      case GameStateEnum.SEC_WORD:
        return this.drawWordState()
      case GameStateEnum.TRD_WORD:
        return this.drawWordState()
      case GameStateEnum.FTH_WORD:
        return this.drawWordState()
      case GameStateEnum.GUESSES:
        return this.drawGuessState()
      case GameStateEnum.FINISHED:
        return this.drawResultsState()
      default:
        return <h3>ERROR</h3>
    }
  }
}

export default Board
