import React from 'react'
import Grid from '@material-ui/core/Grid'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../utils/theme'
import './App.css'
import PropTypes from 'prop-types'

function App (props) {
  const background = {
    backgroundImage: 'url(./static/media/images/background.png)'
  }

  return (
      <ThemeProvider theme={theme}>
        <Grid container className="main-grid">
          <Grid item xs={false} md={1} lg={2} xl={3} style={background} className="background"/>
          <Grid container item xs={12} md={10} lg={8} xl={6} className="content">
            <Grid container item xs={12} direction="column" justify="space-between">
              <Grid item style={{ marginBottom: '30px' }}>
                { props.children }
              </Grid>
              <Grid item className="credits">
                <span className="title">
                  Fiesta De Los Muertos v2.2.0 (05/2021)
                </span>
                { ' - ' }
                <span className="implem">
                  Implémentation : Maxence Blomme
                </span>
                { ' - ' }
                <span className="design">
                  Design : Nolwenn Le Guennec
                </span>
                { ' - ' }
                <span className="source">
                  <a rel="noreferrer" target="_blank" href="https://gitlab.com/maxence.blomme/fiesta-de-los-muertos">Code Source</a>
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={false} md={1} lg={2} xl={3} style={background} className="background revert"/>
        </Grid>
      </ThemeProvider>
  )
}

App.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default App
