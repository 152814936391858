import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import './image.css'
import PropTypes from 'prop-types'

class PrettyTopRow extends Component {
  static get propTypes () {
    return {
      column: PropTypes.bool
    }
  }

  render () {
    const cornerUp = {
      backgroundImage: 'url(./static/media/images/corner-up.png)'
    }
    const top = {
      backgroundImage: 'url(./static/media/images/top.png)',
      backgroundPosition: 'center'
    }
    const topSide = {
      backgroundImage: 'url(./static/media/images/top-side.png)',
      backgroundPosition: 'right',
      height: '50px'
    }

    if (this.props.column) {
      return (
          <Grid container item justify="center" className="image-row">
            <Grid item xs={2} md={2} lg={1} style={cornerUp} className="image"/>
            <Grid item xs={false} md={2} lg={3} style={topSide} className="image"/>
            <Grid item xs={8} md={4} lg={4} style={top} className="image"/>
            <Grid item xs={false} md={2} lg={3} style={topSide} className="image revert"/>
            <Grid item xs={2} md={2} lg={1} style={cornerUp} className="image revert"/>
          </Grid>
      )
    }

    return (
        <Grid container item xs={12} justify="center" className="image-row">
          <Grid item xs={2} md={2} lg={1} style={cornerUp} className="image"/>
          <Grid item xs={false} md={2} lg={3} style={topSide} className="image"/>
          <Grid item xs={8} md={4} lg={4} style={top} className="image"/>
          <Grid item xs={false} md={2} lg={3} style={topSide} className="image revert"/>
          <Grid item xs={2} md={2} lg={1} style={cornerUp} className="image revert"/>
        </Grid>
    )
  }
}

export default PrettyTopRow
