import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import './image.css'
import PropTypes from 'prop-types'
import PrettyTopRow from './PrettyTopRow'
import PrettyBottomRow from './PrettyBottomRow'

class PrettyBaseCard extends Component {
  static get propTypes () {
    return {
      children: PropTypes.any,
      bottom: PropTypes.any,
      style: PropTypes.any,
      large: PropTypes.bool
    }
  }

  render () {
    const md = this.props.large ? 11 : 8
    const lg = this.props.large ? 11 : 6

    return (
        <Grid container item justify="center" xs={12} sm={11} md={md} lg={lg} className="content-card" style={this.props.style}>
            <PrettyTopRow />
            <Grid container item justify="center" xs={11}>
                { this.props.children }
            </Grid>
          <PrettyBottomRow>
            { this.props.bottom ? this.props.bottom : '' }
          </PrettyBottomRow>
        </Grid>
    )
  }
}

export default PrettyBaseCard
