import React, { Component } from 'react'
import './Game.css'
import ReconnectingWebSocket from 'reconnecting-websocket'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import PlayerRegistration from './registration/PlayerRegistration'
import { GameStateEnum, RequestTypeEnum } from '../../utils/enums.js'
import Lobby from './lobby/Lobby'
import Board from './board/Board'
import { shuffle } from '../../utils/shuffle'
import PrettyBaseCard from '../common/PrettyBaseCard'
import { normalize } from '../../utils/normalize'

class Game extends Component {
  constructor (props) {
    super(props)

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const gameId = urlParams.get('game_id')

    this.gameExists = this.gameExists.bind(this)
    this.playNotification = this.playNotification.bind(this)
    this.onMessage = this.onMessage.bind(this)
    this.treatMessage = this.treatMessage.bind(this)
    this.getPlayers = this.getPlayers.bind(this)
    this.setPlayers = this.setPlayers.bind(this)
    this.removePlayer = this.removePlayer.bind(this)
    this.addPlayer = this.addPlayer.bind(this)
    this.reconnectPlayer = this.reconnectPlayer.bind(this)
    this.sendPlayer = this.sendPlayer.bind(this)
    this.sendReconnect = this.sendReconnect.bind(this)
    this.sendWord = this.sendWord.bind(this)
    this.sendGuesses = this.sendGuesses.bind(this)
    this.sendReset = this.sendReset.bind(this)
    this.getSkulls = this.getSkulls.bind(this)
    this.getResults = this.getResults.bind(this)
    this.getConstraints = this.getConstraints.bind(this)
    this.startGame = this.startGame.bind(this)
    this.sendThemeParams = this.sendThemeParams.bind(this)
    this.sendConstraintAmount = this.sendConstraintAmount.bind(this)
    this.canStart = this.canStart.bind(this)
    this.askNewCharacter = this.askNewCharacter.bind(this)
    this.drawContent = this.drawContent.bind(this)

    const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws'
    const webSocket = new ReconnectingWebSocket(wsScheme + '://' + window.location.host + '/ws/game/' + gameId + '/')

    webSocket.onmessage = this.onMessage

    this.state = {
      gameExists: -1,
      players: [],
      playerName: '',
      registerTry: 0,
      registered: false,
      started: false,
      loaded: false,
      gameId: gameId,
      webSocket: webSocket,
      game: null,
      resetting: false,
      colorBlind: false,
      prevWord: '',
      ack: false,
      characters: [],
      guessWords: [],
      results: [],
      paused: false,
      constraints: [],
      constraintAmount: 0,
      useTheme: false,
      randomThemes: true,
      theme1: '',
      theme2: '',
      customTheme: []
    }
  }

  componentDidMount () {
    this.gameExists()
    this.getPlayers()
    this.getConstraints()
  }

  gameExists () {
    fetch('api/gameExists?format=json&game_id=' + this.state.gameId, {
      method: 'GET'
    }).then(response => {
      return response.json()
    }).then(data => {
      this.setState({
        gameExists: data.length > 0 ? 1 : 0
      })
    })
  }

  playNotification () {
    const notification = document.getElementById('notification')
    notification.play()
  }

  onMessage (message) {
    this.treatMessage(JSON.parse(message.data))
  }

  treatMessage (data) {
    let changedState = false
    if (data.game) {
      changedState = this.state.game !== null && this.state.game.state !== data.game.state
      this.setState({
        game: data.game,
        started: data.game.started,
        constraintAmount: data.game.constraints,
        useTheme: data.game.use_theme,
        randomThemes: data.game.random_themes,
        theme1: data.game.theme1,
        theme2: data.game.theme2,
        customTheme: data.game.custom_theme
      })
    }
    if (changedState) {
      this.playNotification()
    }

    const characters = []
    const guessWords = []

    switch (data.type) {
      case RequestTypeEnum.RESET:
        if (data.error !== 1) {
          if (data.resetting) {
            this.setState({ resetting: true, started: false })
          } else {
            this.setState({
              resetting: false,
              prevWord: '',
              ack: false,
              characters: [],
              guessWords: [],
              results: [],
              constraints: [],
              paused: false
            })
            this.setPlayers(data)
          }
        }
        break
      case RequestTypeEnum.REGISTER_PLAYER:
        this.addPlayer(data)
        break
      case RequestTypeEnum.START_GAME:
        this.setState({
          started: true,
          resetting: data.creating,
          prevWord: data.word ?? '',
          players: data.players ?? this.state.players,
          constraints: data.constraints
        })
        break
      case RequestTypeEnum.GET_PLAYERS:
        this.setPlayers(data)
        break
      case RequestTypeEnum.REMOVE_PLAYER:
        this.removePlayer(data)
        break
      case RequestTypeEnum.RECONNECT_PLAYER:
        this.reconnectPlayer(data)
        break
      case RequestTypeEnum.ADD_WORD:
        if (data.ack) {
          this.setState({ ack: true })
        } else if (!changedState) {
          this.setState({
            players: this.state.players.map(player => {
              if (player.name === data.player) {
                player.hasFinished = true
              }
              return player
            })
          })
        } else {
          this.setState({
            players: this.state.players.map(player => {
              player.hasFinished = false
              return player
            }),
            ack: false,
            prevWord: data.word
          })
          if (data.game.state === GameStateEnum.GUESSES) {
            this.getSkulls()
          }
        }
        break
      case RequestTypeEnum.GET_SKULLS:
        data.skulls.map(skull => {
          characters.push({ name: skull.character, index: skull.index })
          if (skull.played) {
            guessWords.push({ word: skull.fth_word, index: skull.index })
          }
          return null
        })
        shuffle(characters)
        shuffle(guessWords)

        this.setState({
          characters: characters,
          guessWords: guessWords
        })
        break
      case RequestTypeEnum.SEND_GUESSES:
        if (data.ack) {
          this.setState({ ack: true })
        } else if (!changedState) {
          this.setState({
            players: this.state.players.map(player => {
              if (player.name === data.player) {
                player.hasFinished = true
              }
              return player
            })
          })
        } else {
          this.setState({
            players: this.state.players.map(player => {
              player.hasFinished = false
              return player
            }),
            ack: false
          })
          this.getResults()
        }
        break
      case RequestTypeEnum.GET_RESULTS:
        this.setState({
          characters: data.characters,
          results: data.result_skulls
        })
        break
      case RequestTypeEnum.GET_CONSTRAINTS:
        this.setState({ constraints: data.constraints })
        break
      case RequestTypeEnum.SET_CONSTRAINT_AMOUNT:
        this.setState({ constraintAmount: data.amount })
        break
      case RequestTypeEnum.SET_THEME:
        this.setState({
          useTheme: data.useTheme !== null ? data.useTheme : this.state.useTheme,
          randomThemes: data.randomThemes !== null ? data.randomThemes : this.state.randomThemes,
          theme1: data.theme1 !== null ? data.theme1 : this.state.theme1,
          theme2: data.theme2 !== null ? data.theme2 : this.state.theme2,
          customTheme: data.customTheme !== null ? data.customTheme : this.state.customTheme
        })
        break
      case RequestTypeEnum.PICK_NEW_CHARACTER:
        this.setState({ prevWord: data.new_character })
        break
      default:
        break
    }
  }

  getPlayers () {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.GET_PLAYERS,
      game_id: this.state.gameId
    }))
  }

  setPlayers (data) {
    if (data.error !== 1) {
      this.setState({
        players: data.players
      })
    }
  }

  removePlayer (data) {
    if (this.state.game && this.state.game.state === GameStateEnum.LOBBY) {
      this.setState({
        players: this.state.players.filter(p => p.name !== data.name)
      })
    } else {
      this.setState({
        players: this.state.players.map(player => {
          if (player.name === data.name) {
            player.connected = false
          }
          return player
        }),
        paused: true
      })
    }
  }

  addPlayer (data) {
    if (data.error === 1) {
      this.setState({
        playerName: '',
        registerTry: this.state.registerTry + 1
      })
      return
    }

    if (data.self) {
      this.setState({
        playerName: data.player.name,
        registered: true
      })
    }

    const players = this.state.players
    players.push(data.player)
    this.setState({ players: players })
  }

  reconnectPlayer (data) {
    if (data.error === 1) {
      this.setState({
        playerName: '',
        registerTry: this.state.registerTry + 1
      })
      return
    }

    if (data.self) {
      this.setState({
        playerName: data.player.name,
        registered: true,
        ack: data.player_statuses ? data.player_statuses.find(player => player.name === data.player.name).has_finished : true
      })

      if (data.word) {
        // State is a word state
        this.setState({
          prevWord: data.word
        })
      } else if (data.skulls) {
        // State is guessing state
        this.treatMessage({
          type: RequestTypeEnum.GET_SKULLS,
          skulls: data.skulls
        })
      } else if (data.result_skulls) {
        // State is results state
        this.treatMessage({
          type: RequestTypeEnum.GET_RESULTS,
          result_skulls: data.result_skulls,
          characters: data.characters
        })
      }
    }

    const players = this.state.players.map(player => {
      if (player.name === data.player.name) {
        player.connected = true
      }
      if (data.player_statuses) {
        const foundInStatuses = data.player_statuses.find(playerStat => playerStat.name === player.name)
        if (foundInStatuses) {
          player.hasFinished = foundInStatuses.has_finished
        }
      }
      return player
    })
    this.setState({
      players: players,
      paused: players.some(player => !player.connected)
    })
  }

  startGame () {
    if (this.canStart()) {
      this.state.webSocket.send(JSON.stringify({
        type: RequestTypeEnum.START_GAME,
        game_id: this.state.gameId
      }))
      this.setState({ started: true, resetting: true })
    }
  }

  getSkulls () {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.GET_SKULLS,
      game_id: this.state.gameId
    }))
  }

  getResults () {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.GET_RESULTS,
      game_id: this.state.gameId
    }))
  }

  getConstraints () {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.GET_CONSTRAINTS,
      game_id: this.state.gameId
    }))
  }

  sendPlayer (playerName) {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.REGISTER_PLAYER,
      game_id: this.state.gameId,
      name: playerName
    }))
  }

  sendReconnect (playerName) {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.RECONNECT_PLAYER,
      game_id: this.state.gameId,
      name: playerName
    }))
  }

  sendWord (word) {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.ADD_WORD,
      game_id: this.state.gameId,
      word: word
    }))
  }

  sendGuesses (guesses) {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.SEND_GUESSES,
      game_id: this.state.gameId,
      guesses: guesses
    }))
  }

  sendReset () {
    if (!window.confirm('Recommencer une nouvelle partie ?')) {
      return
    }

    this.setState({ resetting: true })
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.RESET,
      game_id: this.state.gameId
    }))
  }

  sendConstraintAmount (amount) {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.SET_CONSTRAINT_AMOUNT,
      amount: amount
    }))
  }

  sendThemeParams (useTheme, randomThemes, theme1, theme2, customTheme = null) {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.SET_THEME,
      useTheme: useTheme,
      randomThemes: randomThemes,
      theme1: theme1,
      theme2: theme2,
      customTheme: customTheme
    }))
  }

  askNewCharacter () {
    if (this.state.game.state === GameStateEnum.FST_WORD && !this.state.ack) {
      this.state.webSocket.send(JSON.stringify({
        type: RequestTypeEnum.PICK_NEW_CHARACTER
      }))
    }
  }

  canStart () {
    const playerCount = this.state.players.length
    return playerCount >= 4 && playerCount <= 8
  }

  drawContent () {
    if (this.state.gameExists === -1) {
      return (
          <PrettyBaseCard>
            <h2 className="dark-blue">
              { 'Chargement...' }
            </h2>
          </PrettyBaseCard>
      )
    } else if (this.state.gameExists === 0) {
      window.location.href = '/'
      return (
          <div />
      )
    } else if (!this.state.registered) {
      return (
          <PlayerRegistration gameName={this.state.gameId} try={this.state.registerTry} started={this.state.started}
                              players={this.state.players} sendPlayer={this.sendPlayer}
                              sendReconnect={this.sendReconnect}/>
      )
    } else if (!this.state.started) {
      if (this.state.resetting) {
        return (
            <PrettyBaseCard>
                <h2 className="dark-blue">
                  { "Creation d'une nouvelle partie..." }
                </h2>
            </PrettyBaseCard>
        )
      } else {
        return (
            <Lobby startGame={this.startGame} players={this.state.players}
                   canStart={this.canStart}
                   sendConstraintAmount={this.sendConstraintAmount}
                   constraintAmount={this.state.constraintAmount}
                   sendThemeParams={this.sendThemeParams}
                   useTheme={this.state.useTheme}
                   randomThemes={this.state.randomThemes}
                   theme1={this.state.theme1}
                   theme2={this.state.theme2}
                   customTheme={this.state.customTheme}
                   playerName={this.state.playerName} />
        )
      }
    } else if (!this.state.resetting) {
      return (
          <Board players={this.state.players}
                 playerName={this.state.playerName}
                 game={this.state.game}
                 sendWord={this.sendWord}
                 prevWord={this.state.prevWord}
                 ack={this.state.ack}
                 sendGuesses={this.sendGuesses}
                 characters={this.state.characters}
                 guessWords={this.state.guessWords}
                 results={this.state.results}
                 paused={this.state.paused}
                 constraints={this.state.constraints}
                 askNewCharacter={this.askNewCharacter}
                 useTheme={this.state.useTheme}
                 theme1={this.state.theme1}
                 theme2={this.state.theme2} />
      )
    } else {
      return (
          <PrettyBaseCard>
            <h2 className="dark-blue">
              { 'Lancement de la partie...' }
            </h2>
          </PrettyBaseCard>
      )
    }
  }

  render () {
    return (
        <Grid container item xs={12} justify="center" className="game">
          <audio id="notification">
            <source src="/static/media/sound/notification.mp3"/>
          </audio>
          {
            this.drawContent()
          }
          <Grid container item justify="center" xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
            {
              this.state.registered &&
              this.state.started &&
              !this.state.resetting &&
              <Grid item xs={12} md={6} style={{ marginTop: '15px' }}>
                <Button variant="contained" color="secondary" onClick={this.sendReset}>{ normalize('Recommencer une partie') }</Button>
              </Grid>
            }
            <Grid item xs={12} md={6} style={{ marginTop: '15px' }}>
              <Button variant="contained" color="secondary" onClick={() => window.open('/howto', '_blank')}>{ normalize('Comment jouer ?') }</Button>
            </Grid>
          </Grid>
        </Grid>
    )
  }
}

export default Game
