import React from 'react'
import './GuessSkull.css'
import { normalize } from '../../../utils/normalize'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import ButtonBase from '@material-ui/core/ButtonBase'
import { CardTypeEnum } from '../../../utils/enums'
import Card from '../common/Card'

class GuessSkull extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      setGuess: props.setGuess,
      anchorEl: null,
      buttonLabel: null
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  static get propTypes () {
    return {
      setGuess: PropTypes.func,
      characters: PropTypes.array,
      guessWord: PropTypes.string,
      ack: PropTypes.bool
    }
  }

  handleClick (event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose () {
    this.setState({ anchorEl: null })
  }

  handleSelect (index) {
    this.state.setGuess(index)
    this.setState({ buttonLabel: this.props.characters.find(character => character.index === index).name })
    this.handleClose()
  }

  render () {
    return (
        <Grid container item direction="column" xs={11} sm={6} lg={4} className="guess-skull">
          {
            this.state.buttonLabel &&
            <Grid container item justify="center" alignItems="center" className="content-card">
              <Grid item xs={11}>
                <h3 className="proposition">
                  { normalize(this.state.buttonLabel) }
                </h3>
              </Grid>
            </Grid>
          }
          <Grid item>
            <ButtonBase focusRipple
                        onClick={this.handleClick}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        disabled={this.props.ack}>
              <Card type={CardTypeEnum.FTH_WORD}>
                <h3 className="handwriting">
                  { this.props.guessWord }
                </h3>
              </Card>
            </ButtonBase>
            <Menu
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}>
              {
                this.props.characters.map((character, i) =>
                    <MenuItem key={i} onClick={() => this.handleSelect(character.index)}>{ normalize(character.name) }</MenuItem>
                )
              }
            </Menu>
          </Grid>
        </Grid>
    )
  }
}

export default GuessSkull
