import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  typography: {
    body1: {
      fontFamily: 'KleponIjo, Helvetica, serif',
      fontWeight: 'normal',
      fontSize: '1rem',
      lineHeight: '1.5',
      letterSpacing: '0.00938em'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: 'KleponIjo, Helvetica, serif',
        fontWeight: 'normal',
        lineHeight: '1.5',
        textTransform: 'none'
      },
      label: {
        fontSize: '1.6rem',
        paddingRight: '15px',
        paddingLeft: '15px'
      }
    },
    MuiCheckbox: {
      root: {
        color: 'none'
      }
    },
    MuiInputBase: {
      input: {
        textAlign: 'center',
        fontFamily: 'GabeTheGommba, Helvetica, serif',
        fontSize: '1.5rem'
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: 'KleponIjo, Helvetica, serif',
        fontWeight: 'normal',
        borderBottom: 'none'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.5rem'
      }
    }
  },
  palette: {
    primary: {
      light: '#ffe64c',
      main: '#f7b400',
      dark: '#bf8500',
      contrastText: '#000000'
    },
    secondary: {
      light: '#ff713f',
      main: '#db3e10',
      dark: '#a10000',
      contrastText: '#fff'
    }
  }
})
