export const RequestTypeEnum = {
  RESET: 1,
  REGISTER_PLAYER: 2,
  GET_PLAYERS: 3,
  REMOVE_PLAYER: 4,
  GET_SKULLS: 5,
  ADD_WORD: 6,
  SEND_GUESSES: 7,
  START_GAME: 8,
  GET_RESULTS: 9,
  RECONNECT_PLAYER: 10,
  GET_CONSTRAINTS: 11,
  SET_CONSTRAINT_AMOUNT: 12,
  PICK_NEW_CHARACTER: 13,
  SET_THEME: 14
}

export const GameStateEnum = {
  FST_WORD: 0,
  SEC_WORD: 1,
  TRD_WORD: 2,
  FTH_WORD: 3,
  GUESSES: 4,
  FINISHED: 5,
  LOBBY: 6
}

export const ResultShowModeEnum = {
  WORD_1: 1,
  WORD_2: 2,
  WORD_3: 3,
  WORD_4: 4,
  WORD_ALL: 5,
  GUESSES: 6
}

export const CardTypeEnum = {
  CONSTRAINT: -1,
  CHARACTER: 0,
  FST_WORD: 1,
  SEC_WORD: 2,
  TRD_WORD: 3,
  FTH_WORD: 4,
  RESULT_1: 5,
  RESULT_2: 6,
  RESULT_3: 7,
  RESULT_4: 8,
  THEME: 9
}
