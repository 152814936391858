import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, Input } from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import './PlayerRegistration.css'
import '../../common/image.css'
import PrettyTopRow from '../../common/PrettyTopRow'
import PrettyTitle from '../../common/PrettyTitle'
import PrettyBottomRow from '../../common/PrettyBottomRow'
import PropTypes from 'prop-types'

class PlayerRegistration extends Component {
  constructor (props) {
    super(props)

    this.state = {
      registering: false,
      name: '',
      err: false,
      sendPlayer: props.sendPlayer,
      sendReconnect: props.sendReconnect,
      spectator: false,
      selectedReconnect: ''
    }

    this.register = this.register.bind(this)
    this.reconnect = this.reconnect.bind(this)
    this.drawBaseRegistration = this.drawBaseRegistration.bind(this)
    this.drawPlayerReconnection = this.drawPlayerReconnection.bind(this)
    this.drawRegistering = this.drawRegistering.bind(this)
  }

  static get propTypes () {
    return {
      sendPlayer: PropTypes.func,
      sendReconnect: PropTypes.func,
      players: PropTypes.array,
      try: PropTypes.number,
      gameName: PropTypes.string,
      started: PropTypes.bool
    }
  }

  register (event) {
    this.setState({ registering: true, err: false })
    if (this.checkPlayer(this.state.name)) {
      this.state.sendPlayer(this.state.name)
    } else {
      this.setState({ registering: false, err: true })
    }

    // Prevent form redirection
    event.preventDefault()
  }

  reconnect (event) {
    this.setState({ registering: true, err: false })
    if (this.props.players.find(player => player.name === this.state.selectedReconnect && !player.connected)) {
      this.state.sendReconnect(this.state.selectedReconnect)
    } else {
      this.setState({ registering: false, err: true })
    }

    // Prevent form redirection
    event.preventDefault()
  }

  checkPlayer (name) {
    return name && name.length > 0 && name.length <= 16
  }

  componentDidUpdate (prevProps) {
    if (this.props.try !== prevProps.try) {
      this.setState({ registering: false, err: true })
    }
  }

  drawBaseRegistration () {
    const mid = {
      backgroundImage: 'url(./static/media/images/mid-side.png)',
      backgroundPosition: 'left',
      height: '60px'
    }

    return (
        <form onSubmit={this.register}>
          <Grid container item justify="center" xs={12}>
            <Grid item xs={11}>
              <h2 className="dark-blue">
                Bienvenue sur la partie <span className="handwriting game-name">{this.props.gameName}</span>
              </h2>
            </Grid>
            <Grid container item xs={12} justify="center">
              <Grid item xs={false} sm={1} md={2} lg={3} style={mid} className="image"/>
              <Grid container item justify="center" xs={11} sm={10} md={8} lg={6}>
                <Grid item xs={12}>
                  <h2 className="dark-blue" style={{ marginTop: 0, marginBottom: 0 }}>
                    Quel est ton nom ?
                  </h2>
                </Grid>
                <Grid item xs={11} sm={10} md={8} lg={6}>
                  <Input fullWidth
                         color="secondary"
                         onChange={(event) => this.setState({ name: event.target.value })}
                         style={{ marginBottom: '30px' }}/>
                </Grid>
              </Grid>
              <Grid item xs={false} sm={1} md={2} lg={3} style={mid} className="image revert"/>
            </Grid>
            <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" disabled={!this.state.name || !this.checkPlayer(this.state.name)}>
                  { "M'enregistrer" }
                </Button>
            </Grid>
            <Grid item xs={11}>
              {
                this.state.err &&
                <span className="orange" style={{ fontSize: '1.5rem' }}>
                              Erreur : ce joueur existe deja ou les informations rentrees sont invalides.
                              Ton nom doit avoir entre 1 et 16 caracteres
                          </span>
              }
            </Grid>
          </Grid>
        </form>
    )
  }

  drawRegistering () {
    return (
        <Grid item xs={11}>
          <h2 className="dark-blue">
            Enregistrement...
          </h2>
        </Grid>
    )
  }

  drawPlayerReconnection () {
    const mid = {
      backgroundImage: 'url(./static/media/images/mid-side.png)',
      backgroundPosition: 'left',
      height: '60px'
    }

    const disconnected = this.props.players.filter(player => !player.connected)

    return (
        <form onSubmit={this.reconnect}>
          <Grid container item justify="center" xs={12}>
            <Grid item xs={11}>
              <h2 className="dark-blue">
                La partie <span className="handwriting game-name">{this.props.gameName}</span> a deja commence
              </h2>
            </Grid>
            {
              disconnected.length === 0 &&
              <Grid item xs={11}>
                <p>
                  Il te faudra attendre la prochaine partie pour rejoindre
                </p>
              </Grid>
            }
            {
              disconnected.length > 0 &&
              <Grid container item xs={12} justify="center">
                <Grid item xs={false} sm={1} md={2} lg={3} style={mid} className="image"/>
                <Grid item xs={11} sm={10} md={8} lg={6}>
                  <h3>
                    Qui es-tu ?
                  </h3>
                </Grid>
                <Grid item xs={false} sm={1} md={2} lg={3} style={mid} className="image revert"/>
              </Grid>
            }
            {
              disconnected.length > 0 &&
              <Grid item xs={11} sm={8} md={6} lg={4} style={{ marginBottom: '15px' }}>
                <RadioGroup aria-label="playerReconnect" name="playerReconnect1"
                            onChange={(event) => this.setState({ selectedReconnect: event.target.value })}>
                  {
                    disconnected.map((player, i) =>
                        <FormControlLabel key={i} value={player.name} control={<Radio color="secondary"/>}
                                          label={player.name}/>
                    )
                  }
                </RadioGroup>
              </Grid>
            }
            {
              disconnected.length > 0 &&
              <Grid item xs={11}>
                <Button type="submit" variant="contained" color="primary" disabled={!this.state.selectedReconnect}>
                  Reconnexion
                </Button>
              </Grid>
            }
          </Grid>
        </form>
    )
  }

  render () {
    return (
        <Grid container item xs={12} sm={11} justify="center" spacing={1}
              className="content-card player-registration">
          <PrettyTopRow/>
          <PrettyTitle/>
          {
            this.props.started &&
            this.drawPlayerReconnection()
          }
          {
            !this.props.started &&
            this.state.registering &&
            this.drawRegistering()
          }
          {
            !this.props.started &&
            !this.state.registering &&
            this.drawBaseRegistration()
          }
          <PrettyBottomRow/>
        </Grid>
    )
  }
}

export default PlayerRegistration
