import React, { Component } from 'react'
import './PlayerList.css'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

class PlayerList extends Component {
  static get propTypes () {
    return {
      players: PropTypes.array,
      style: PropTypes.any,
      playerName: PropTypes.string
    }
  }

  render () {
    return (
        <Grid container item justify="center" xs={11} className="player-list" style={this.props.style}>
          {
            this.props.players.map((player, i) =>
                <Grid key={i} item xs={10} sm={6} md={4} style={{ position: 'relative' }}>
                  <div className={'player ' + (player.hasFinished ? 'finished' : '')}>
                    <h3 className="handwriting">
                      { player.name }
                    </h3>
                  </div>
                  {
                    !player.connected &&
                    <div className={'reconnecting player ' + (player.hasFinished ? 'finished' : '')}>
                      <h3 className="orange" style={{ margin: 0 }}>
                        Reconnexion...
                      </h3>
                    </div>
                  }
                  {
                    player.name === this.props.playerName &&
                    <h4 className="light-blue">
                      (me)
                    </h4>
                  }
                </Grid>
            )
          }
        </Grid>
    )
  }
}

export default PlayerList
