import React from 'react'
import './ResultSkull.css'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { ResultShowModeEnum, CardTypeEnum } from '../../../utils/enums.js'
import { normalize } from '../../../utils/normalize'
import PropTypes from 'prop-types'

import Card from '../common/Card'

class ResultSkull extends React.Component {
  constructor (props) {
    super(props)

    const words = [{ word: props.result.fst_word }, { word: props.result.sec_word }, { word: props.result.trd_word }, { word: props.result.fth_word }]
    for (let i = 0; i < words.length; i++) {
      const playerName = props.players.find(player => player.index === ((this.props.result.index - i + props.players.length) % props.players.length)).name
      words[i].player = playerName
    }

    this.state = {
      showMode: ResultShowModeEnum.WORD_1,
      words: words
    }

    this.draw = this.draw.bind(this)
    this.drawWordMode = this.drawWordMode.bind(this)
    this.drawAllWordsMode = this.drawAllWordsMode.bind(this)
    this.drawGuessesMode = this.drawGuessesMode.bind(this)

    this.switchMode = this.switchMode.bind(this)
    this.buttonLabel = this.buttonLabel.bind(this)
  }

  static get propTypes () {
    return {
      result: PropTypes.any,
      characters: PropTypes.array,
      players: PropTypes.array
    }
  }

  draw () {
    switch (this.state.showMode) {
      case ResultShowModeEnum.WORD_ALL:
        return this.drawAllWordsMode()
      case ResultShowModeEnum.GUESSES:
        return this.drawGuessesMode()
      default:
        return this.drawWordMode(this.state.showMode)
    }
  }

  switchMode () {
    switch (this.state.showMode) {
      case ResultShowModeEnum.WORD_1:
        this.setState({ showMode: ResultShowModeEnum.WORD_2 })
        break
      case ResultShowModeEnum.WORD_2:
        this.setState({ showMode: ResultShowModeEnum.WORD_3 })
        break
      case ResultShowModeEnum.WORD_3:
        this.setState({ showMode: ResultShowModeEnum.WORD_4 })
        break
      case ResultShowModeEnum.WORD_4:
        this.setState({ showMode: ResultShowModeEnum.WORD_ALL })
        break
      case ResultShowModeEnum.WORD_ALL:
        this.setState({ showMode: ResultShowModeEnum.GUESSES })
        break
      case ResultShowModeEnum.GUESSES:
        this.setState({ showMode: ResultShowModeEnum.WORD_ALL })
        break
      default:
        break
    }
  }

  buttonLabel () {
    switch (this.state.showMode) {
      case ResultShowModeEnum.WORD_1:
      case ResultShowModeEnum.WORD_2:
      case ResultShowModeEnum.WORD_3:
        return 'Suite'
      case ResultShowModeEnum.WORD_ALL:
        return 'Propositions'
      case ResultShowModeEnum.WORD_4:
      case ResultShowModeEnum.GUESSES:
        return 'Resultat'
      default:
        return 'Erreur'
    }
  }

  drawWordMode (index, xs = false) {
    let type, maxHeight
    switch (index) {
      case ResultShowModeEnum.WORD_1:
        type = CardTypeEnum.RESULT_1
        maxHeight = null
        break
      case ResultShowModeEnum.WORD_2:
        type = CardTypeEnum.RESULT_2
        maxHeight = '60%'
        break
      case ResultShowModeEnum.WORD_3:
        type = CardTypeEnum.RESULT_3
        maxHeight = '70%'
        break
      case ResultShowModeEnum.WORD_4:
        type = CardTypeEnum.RESULT_4
        maxHeight = '75%'
        break
      default:
        break
    }

    return (
        <Grid item xs={xs ? 12 : null}>
          <Card type={type} maxHeight={maxHeight}>
            {
              this.state.words.slice(-index).map((word, i) =>
                <div key={i}>
                  <h3 className="handwriting">
                    { word.word }
                  </h3>
                  <h4 className="light-blue" style={i === index - 1 ? { marginBottom: 0 } : {}}>
                    { word.player }
                  </h4>
                </div>
              )
            }
          </Card>
        </Grid>
    )
  }

  drawAllWordsMode () {
    return (
        <Grid container item justify="center">
          <Grid item xs={10} lg={8}>
            <Card type={CardTypeEnum.CHARACTER}>
              <h3 className="dark-blue">
                { normalize(this.props.result.character) }
              </h3>
            </Card>
          </Grid>
          {
            this.drawWordMode(4, true)
          }
        </Grid>
    )
  }

  drawGuessesMode () {
    return (
        <Grid container item justify="center">
          <Grid item xs={10} lg={8}>
            <Card type={CardTypeEnum.CHARACTER}>
              <h3 className="dark-blue">
                { normalize(this.props.result.character) }
              </h3>
            </Card>
          </Grid>
          <Card type={CardTypeEnum.RESULT_4} maxHeight={'75%'}>
            {
              this.props.result.player_guesses.map((guess, i) =>
                  <div key={i}>
                    <h3>
                      { normalize(this.props.characters.find(character => character.index === guess.character_index).character) }
                    </h3>
                    <h4 className={guess.character_index === this.props.result.index ? 'light-blue' : 'orange'} style={i === this.props.players.length - 1 ? { marginBottom: 0 } : {}}>
                      { this.props.players.find(player => player.index === guess.player_index).name }
                    </h4>
                  </div>
              )
            }
          </Card>
        </Grid>
    )
  }

  render () {
    return (
        <Grid container item direction="column" xs={11} sm={6} lg={4} className="result-skull">
          {
            this.draw()
          }
          <Grid item style={{ textAlign: 'center' }}>
            <Button variant="contained" color="primary" onClick={this.switchMode}>
              {
                this.buttonLabel()
              }
            </Button>
          </Grid>
        </Grid>
    )
  }
}

export default ResultSkull
