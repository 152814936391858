import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, FormControl, InputLabel, MenuItem, Select, TextareaAutosize } from '@material-ui/core'
import './Lobby.css'
import '../../common/image.css'
import PrettyTopRow from '../../common/PrettyTopRow'
import PrettyBottomRow from '../../common/PrettyBottomRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { normalize } from '../../../utils/normalize'

class Lobby extends Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSelect = this.handleSelect.bind(this)

    this.updateThemeParams = this.updateThemeParams.bind(this)
    this.editCustomCharacters = this.editCustomCharacters.bind(this)
    this.changeConstraintAmount = this.changeConstraintAmount.bind(this)
    this.validThemeSelection = this.validThemeSelection.bind(this)
    this.customCharactersString = this.customCharactersString.bind(this)

    this.drawThemeSelector = this.drawThemeSelector.bind(this)
    this.drawExpansionForm = this.drawExpansionForm.bind(this)
    this.drawLobby = this.drawLobby.bind(this)
    this.drawPlayerList = this.drawPlayerList.bind(this)

    this.state = {
      startGame: props.startGame,
      canStart: props.canStart,
      sendConstraintAmount: props.sendConstraintAmount,
      anchorEl: null,
      constraintAmount: props.constraintAmount,
      useTheme: props.useTheme,
      randomThemes: props.randomThemes,
      theme1: props.theme1,
      theme2: props.theme2,
      themes: [],
      customTheme: props.customTheme,
      customThemeStr: this.customCharactersString(props.customTheme)
    }
  }

  static get propTypes () {
    return {
      startGame: PropTypes.func,
      sendConstraintAmount: PropTypes.func,
      sendThemeParams: PropTypes.func,
      canStart: PropTypes.func,
      constraintAmount: PropTypes.number,
      players: PropTypes.array,
      playerName: PropTypes.string,
      useTheme: PropTypes.bool,
      randomThemes: PropTypes.bool,
      theme1: PropTypes.string,
      theme2: PropTypes.string,
      customTheme: PropTypes.array
    }
  }

  componentDidMount () {
    fetch('api/themes?format=json', {
      method: 'GET'
    }).then(response => {
      return response.json()
    }).then(data => {
      this.setState({
        themes: data.sort((a, b) => {
          return a.theme === b.theme ? 0 : a.theme < b.theme ? -1 : 1
        })
      })
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.constraintAmount !== prevProps.constraintAmount) {
      this.setState({ constraintAmount: this.props.constraintAmount })
    }

    if (this.props.useTheme !== prevProps.useTheme) {
      this.setState({ useTheme: this.props.useTheme })
    }

    if (this.props.randomThemes !== prevProps.randomThemes) {
      this.setState({ randomThemes: this.props.randomThemes })
    }

    if (this.props.theme1 !== prevProps.theme1) {
      this.setState({ theme1: this.props.theme1 })
    }

    if (this.props.theme2 !== prevProps.theme2) {
      this.setState({ theme2: this.props.theme2 })
    }

    if (this.props.customTheme.length !== prevProps.customTheme.length ||
        this.props.customTheme.filter((c, i) => prevProps.customTheme[i] !== c).length > 0) {
      this.setState({
        customTheme: this.props.customTheme,
        customThemeStr: this.customCharactersString(this.props.customTheme)
      })
    }
  }

  updateThemeParams (useTheme, randomThemes, theme1, theme2, customTheme = null) {
    if (useTheme !== null) {
      this.setState({ useTheme: useTheme })
      this.props.sendThemeParams(useTheme, null, null, null)
    }

    if (randomThemes !== null) {
      this.setState({ randomThemes: randomThemes })
      this.props.sendThemeParams(null, randomThemes, null, null)
    }

    if (theme1 !== null) {
      this.setState({ theme1: theme1 })
      if (theme1 !== 'custom' && this.state.theme2 === 'default') {
        this.setState({ theme2: '' })
        this.props.sendThemeParams(null, null, theme1, '')
      } else {
        this.props.sendThemeParams(null, null, theme1, null)
      }
    }

    if (theme2 !== null) {
      this.setState({ theme2: theme2 })
      this.props.sendThemeParams(null, null, null, theme2)
    }

    if (customTheme !== null) {
      this.props.sendThemeParams(null, null, null, null, customTheme)
    }
  }

  handleClick (event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose () {
    this.setState({ anchorEl: null })
  }

  handleSelect (amount) {
    this.state.sendConstraintAmount(amount)
    this.setState({ constraintAmount: amount })
    this.handleClose()
  }

  changeConstraintAmount (plus) {
    let amount = this.state.constraintAmount + (plus ? 1 : -1)
    amount = amount <= 0 ? 0 : amount >= 4 ? 4 : amount
    this.state.sendConstraintAmount(amount)
    this.setState({ constraintAmount: amount })
  }

  editCustomCharacters () {
    const characters = this.state.customThemeStr.match(/[\w\-() ]+/g)
    const list = [...new Set(characters)]
    this.setState({ customTheme: list, customThemeStr: this.customCharactersString(list) })
    this.updateThemeParams(null, null, null, null, list)
  }

  customCharactersString (list) {
    let out = ''
    for (const element of list) {
      out += element + ','
    }
    return out
  }

  validThemeSelection () {
    return !this.state.useTheme ||
        this.state.randomThemes ||
        (this.state.theme1 !== '' && this.state.theme2 !== '') ||
        (this.state.theme1 === 'custom' && this.state.theme2 === '' && this.state.customTheme.length >= 8)
  }

  drawThemeSelector (firstTheme, selected = '', displayDefault = false, displayEmpty = false) {
    return (
      <FormControl className="theme-select">
        <InputLabel id="demo-simple-select-label">{ normalize('Thème') }</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={firstTheme ? this.state.theme1 : this.state.theme2}
          onChange={(event) =>
            firstTheme
              ? this.updateThemeParams(null, null, event.target.value, null)
              : this.updateThemeParams(null, null, null, event.target.value)
          }
        >
          {
            displayEmpty &&
            <MenuItem value="">
              { normalize('Pas de second thème') }
            </MenuItem>
          }
          {
            displayDefault &&
            <MenuItem value="default">
              { normalize('Liste par défaut') }
            </MenuItem>
          }
          {
            this.state.themes.filter(theme => theme.theme !== selected).map(theme =>
              <MenuItem key={theme.theme} value={theme.theme}>
                { normalize(theme.theme + ' - ' + theme.character_count + ' personnages') }
              </MenuItem>
            )
          }
          {
            firstTheme &&
            <MenuItem value="custom">
              { normalize('Liste personnalisée') }
            </MenuItem>
          }
        </Select>
      </FormControl>
    )
  }

  drawExpansionForm () {
    return (
      <Grid container item justify="center" xs={12}>
        <Grid item xs={12} sm={7}>
          <FormControlLabel control={
            <Checkbox onChange={(event) =>
              this.updateThemeParams(event.target.checked, null, null, null)
            }
                      name="private"
                      color="secondary"
                      checked={this.state.useTheme} />
          } label={normalize('Personnages à thème')}
                            classes={{
                              root: 'checkbox-body',
                              label: 'checkbox-label'
                            }}/>
        </Grid>
        {
          this.state.useTheme &&
          <Grid item xs={12} sm={7}>
            <FormControlLabel control={
              <Checkbox onChange={(event) => {
                this.updateThemeParams(null, event.target.checked, null, null)
              }}
                        name="private"
                        color="secondary"
                        checked={this.state.randomThemes} />
            } label={normalize('Thèmes aléatoires')}
                              classes={{
                                root: 'checkbox-body',
                                label: 'checkbox-label'
                              }}/>
          </Grid>
        }
        {
          this.state.useTheme &&
          !this.state.randomThemes &&
          <Grid container item justify="center" xs={12}>
            <Grid item xs={11} sm={10} md={8}>
              {
                this.drawThemeSelector(true, this.state.theme2, false, false)
              }
            </Grid>
            <Grid item xs={11} sm={10} md={8}>
              {
                this.drawThemeSelector(false,
                  this.state.theme1,
                  this.state.theme1 === 'custom',
                  this.state.theme1 === 'custom' && this.state.customTheme.length >= 8)
              }
            </Grid>
            {
              this.state.theme1 === 'custom' &&
              <Grid item xs={11} sm={10} md={8}>
                <TextareaAutosize aria-label="custom words"
                                  className="word-input"
                                  placeholder="Personnages séparés par des virgules et/ou retours à la ligne."
                                  value={this.state.customThemeStr}
                                  onChange={(event) => this.setState({ customThemeStr: event.target.value })}
                                  onBlur={this.editCustomCharacters} />

              </Grid>
            }
          </Grid>
        }
      </Grid>
    )
  }

  drawLobby () {
    const players = this.props.players

    return (
        <Grid container item justify="space-between" direction="column" xs={12} className="content-card">
          <PrettyTopRow column/>
          <Grid container item justify="center">
            <Grid item xs={11}>
              <h2 className="dark-blue">
                Attente de joueurs pour votre partie
              </h2>
            </Grid>
            <Grid item xs={11} style={{ marginTop: '50px', marginBottom: '50px' }}>
              {
                players.length < 4 &&
                <h3 className="orange">
                  Il manque {4 - players.length} joueur{players.length === 3 ? '' : 's'} pour commencer
                </h3>
              }
              {
                players.length >= 4 &&
                <Button onClick={this.state.startGame} variant="contained" color="primary"
                        disabled={!this.state.canStart() || !this.validThemeSelection()}>
                  ¡Jugamos!
                </Button>
              }
            </Grid>
            <Grid item xs={11}>
              <h2 className="dark-blue" style={{ marginBottom: '10px' }}>
                Parametres de partie :
              </h2>
            </Grid>
            <Grid container item justify="center" xs={11}>
              <Grid item xs={12} sm={7} className="constraint-amount-label">
                <h3>
                  Nombre de contraintes :
                </h3>
              </Grid>
              <Grid item xs={12} sm={5} className="constraint-amount-selector">
                <h3>
                  <Button className="constraint-button" onClick={() => this.changeConstraintAmount(false)}
                          color="secondary" disabled={this.state.constraintAmount <= 0}>
                    <FontAwesomeIcon icon={faMinusCircle}/>
                  </Button>
                  <span className="constraint-amount">
                                    {this.state.constraintAmount}
                                </span>
                  <Button className="constraint-button" onClick={() => this.changeConstraintAmount(true)}
                          color="secondary" disabled={this.state.constraintAmount >= 4}>
                    <FontAwesomeIcon icon={faPlusCircle}/>
                  </Button>
                </h3>
              </Grid>
            </Grid>
            {
              this.drawExpansionForm()
            }
            <Grid item xs={11} sm={10}>
              <p>
                { "Les contraintes sont sur les mots que vous ecrirez, et peuvent etre de l'ordre de l'orthographe " +
                  '("commence par un R", "pas de E dans le mot"...) ou du type de mot (lieu, objet…)' }
              </p>
              <p>
                { "Rappel : vous ne pouvez ecrire qu'un seul mot (mots composes autorises) et que le mot ne peut pas etre" +
                  ' un nom de personnage !' }
              </p>
            </Grid>
          </Grid>
          <PrettyBottomRow column/>
        </Grid>
    )
  }

  drawPlayerList () {
    const players = this.props.players
    let displayArray = [...Array(8).keys()]
    displayArray = displayArray.map(index => {
      return {
        number: index + 1,
        player: players.length > index ? players[index] : null,
        style: {
          backgroundImage: 'url(./static/media/images/numbers/' + (index + 1).toString() + '.png)'
        }
      }
    })

    return (
        <Grid container item justify="center" xs={12}>
          {
            displayArray.map((element, i) =>
                <Grid key={i} container item justify="center" xs={12} className="player-line">
                  <Grid item xs={3} className="content-card square image" style={element.style}/>
                  <Grid item xs={8} style={{ marginLeft: '6px', position: 'relative' }}>
                    <div className="content-card player-name" style={{ backgroundColor: element.player ? '#ffffff' : '#e6e6e6' }}>
                      <h3 className="handwriting">
                        {element.player !== null ? element.player.name : ''}
                      </h3>
                    </div>
                    {
                      element.player &&
                      element.player.name === this.props.playerName &&
                      <h4 className="light-blue">
                        (me)
                      </h4>
                    }
                  </Grid>
                </Grid>
            )
          }
        </Grid>
    )
  }

  render () {
    return (
        <Grid container item justify="center" spacing={3} xs={12} sm={11} className="lobby">
          <Grid container item justify="center" direction="column" xs={12} sm={10} lg={7}>
            {
              this.drawLobby()
            }
          </Grid>
          <Grid container item justify="center" xs={12} sm={8} lg={5}>
            {
              this.drawPlayerList()
            }
          </Grid>
        </Grid>
    )
  }
}

export default Lobby
